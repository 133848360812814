import React, { ChangeEvent } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';

export interface LabeledPhoneInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  onChange?: (value: string, data: {} | CountryData, event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  label?: string;
  errorText?: string;
  defaultValue: string;
}

const LabeledPhoneInput = React.forwardRef<HTMLInputElement, LabeledPhoneInputProps>(
  (props, ref) => {
    const {
      label,
      errorText,
      className,
      type,
      ...rest
    } = props;

    return (
      <div className='flex flex-col gap-sm flex-1'>
        {!!label && (
          <label
            className={`${rest.required && `after:content-['*'] after:absolute after:left-[calc(100%+5px)] after:text-brand-600`} font-medium text-small max-w-max flex text-gray-900 relative`}
            htmlFor={props.id}
          >
            {label}
          </label>
        )}
        <PhoneInput
          placeholder={rest.placeholder ?? ''}
          value={rest.value}
          containerClass='w-full'
          inputClass='!w-full !h-[48px] !border !border-slate-300 !rounded-lg'
          buttonClass='!rounded-l-lg'
          country='us'
          {...rest}
        />
        {!!(errorText) && (
          <p className={'text-small text-error-600'}>{errorText}</p>
        )}
      </div>
    );
  }
);
LabeledPhoneInput.displayName = 'LabeledPhoneInput';

export { LabeledPhoneInput };
