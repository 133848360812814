import { useTracking } from './index';
import { CommunityAppEnum } from 'generated/types';


export const usePageTracking = () => {
  const { trackEvent } = useTracking();
  return {
    trackPage: (path?: string) => trackEvent({ name: 'page_visit', path: path })
  };
};


export const useCommunityLandingPageTracking = () => {
  const { trackEvent } = useTracking();
  const trackCommunityLandingPage = () =>
    trackEvent({
      name: 'page_visit',
      properties: {
        pageName: 'community_landing'
      }
    });


  return {
    trackCommunityLandingPage
  };
};

export const useJoinCommunityFormTracking = () => {
  const { trackEvent } = useTracking();
  const trackJoinCommunityForm = () => {
    trackEvent({
      name: 'landing_join_form_submitted',
      properties: {
        pageName: 'community_landing_form'
      }
    });
  };
  return {
    trackJoinCommunityForm
  };
};

export const useJoinCommunityTracking = () => {
  const { trackEvent } = useTracking();
  const trackJoinCommunity = (location: 'cta' | 'header') => {
    trackEvent({
      name: 'landing_join_btn_clicked',
      properties: {
        pageName: 'community_landing',
        location
      }
    });
  };
  return {
    trackJoinCommunity
  };
};

export const useCreateCommunityTracking = () => {
  const { trackEvent } = useTracking();

  const trackCreateCommunityPage = () => {
    trackEvent({
      name: 'page_visit',
      properties: {
        pageName: 'create_community'
      }
    });
  };

  const trackSelectCommunityApp = (app: CommunityAppEnum) => {
    trackEvent({
      name: 'select_community_app',
      properties: {
        app
      }
    });
  };

  return {
    trackCreateCommunityPage,
    trackSelectCommunityApp
  };
};

export const useSelectCommunityGoalTracking = () => {
  const { trackEvent } = useTracking();

  const trackSelectCommunityGoals = (goals: string[]) => {
    trackEvent({
      name: 'select_community_goal',
      properties: {
        goals
      }
    });
  };
  return {
    trackSelectCommunityGoals
  };
};

export const useMemberTracking = () => {
  const { trackEvent } = useTracking();

  const trackMagicSearch = (action: 'opened' | 'suggestion_selected' | 'submitted' | 'closed') => {
    trackEvent({
      name: 'magic_search',
      properties: {
        action: action
      }
    });
  };

  const trackConnectModal = (action: 'opened' | 'closed') => {
    trackEvent({
      name: 'connect_modal',
      properties: {
        action,
      }
    });
  };

  const trackConnect = (action: 'connect_selected' | 'worked_with_selected' | 'sent') => {
    trackEvent({
      name: 'connect',
      properties: {
        action
      }
    });
  };

  const trackConnectReaction = () => {
    trackEvent({
      name: 'connect_reacted',
      properties: {}
    });
  };

  const trackWorkedWithReaction = () => {
    trackEvent({
      name: 'worked_with_reacted',
      properties: {
      }
    });
  };

  const trackMessage = (action: "clicked" | "redirected" | "closed") => {
    trackEvent({
      name: 'message',
      properties: {
        action,
      }
    });
  };

  const trackActivityFilterChange = (filter: string) => {
    trackEvent({
      name: 'activity_filter',
      properties: {
        filter
      }
    });
  };

  const trackCopyProfileLink = () => {
    trackEvent({
      name: 'profile_link_copied'
    });
  };

  const trackICanHelp = () => {
    trackEvent({
      name: 'i_can_help_clicked'
    });
  };

  return {
    trackMagicSearch,

    trackConnect,
    trackConnectReaction,
    trackConnectModal,
    trackWorkedWithReaction,

    trackMessage,

    trackCopyProfileLink,
    trackActivityFilterChange,

    trackICanHelp
  };
};
