'use client';

import { FC, useContext } from 'react';

import { Button } from 'components/ui/button';
import LandingContext, { ModalTabs } from 'app/_context/Landing';

import { ILandingConfig } from 'types';

interface IJoinCommunityButtonProps {
  appConfig: ILandingConfig;
  className?: string;
  icon: JSX.Element | null;
  iconLeft?: boolean;
  title: string;
}

export const JoinCommunityButton: FC<IJoinCommunityButtonProps> = (props) => {
  const {
    appConfig,
    className,
    icon,
    iconLeft,
    title,
  } = props;

  const { setTab } = useContext(LandingContext);

  return (
    <Button
      className={`${className} ${iconLeft ? 'flex-row-reverse' : ''}`}
      size="x-large"
      variant={appConfig.buttonVariant}
      onClick={() => setTab(ModalTabs.APPLY)}
    >
      {title}
      {icon}
    </Button>
  );
}
