'use client';

import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { LoaderCircle } from 'lucide-react';

import { KeyIcon, MailIcon } from 'components/icons/v2';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { useResetPasswordMutation } from 'generated/hooks';
import { useFormErrorHandling } from 'hooks/useFormErrorHandling';

interface IResetPasswordForm {
  email: string;
}

const resetPasswordFormSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' })
});

type ForgotPasswordProps = {
  isSent?: boolean;
  onSuccess?: () => void
}

export default function ForgotPassword(props: ForgotPasswordProps) {
  const [isSent, setIsSent] = useState<boolean>(props.isSent || false);
  const { handleErrors } = useFormErrorHandling();
  const [resetPasswordState, resetPassword] = useResetPasswordMutation();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors: errors },
    setError,
    getValues,
    clearErrors
  } = useForm<IResetPasswordForm>({
    mode: 'all',
    resolver: zodResolver(resetPasswordFormSchema),
    defaultValues: {
      email: ''
    }
  });

  const resetPasswordEmailValue = useWatch({
    control: control,
    name: 'email'
  });

  const onResetPasswordSubmit = useCallback(async (data: IResetPasswordForm) => {
    try {
      const response = await resetPassword({
        email: data.email
      });

      if (response.error) {
        return handleErrors<IResetPasswordForm>(response.error, setError, {});
      }
      setIsSent(true);
      if (props.onSuccess) props.onSuccess();
    } catch (error) {
      console.error(error);
    }
  }, [handleErrors, props, resetPassword, setError]);

  useEffect(() => {
    clearErrors('root');
  }, [clearErrors, resetPasswordEmailValue]);

  useEffect(() => {
    reset();
  }, []);

  return (
    <>
      <div className='flex flex-col gap-6 items-center text-gray-900 z-1'>
        <div
          className='flex justify-center items-center border border-gray-200 gap-3 w-14 h-14 rounded-xl text-gray-800'>
          {!isSent ? (
            <KeyIcon width={28} height={28} />
          ) : (
            <MailIcon width={28} height={28} />
          )}
        </div>
        <div className='flex flex-col gap-3 items-center'>
          <p className='text-display-sm font-semibold'>
            {!isSent ? 'Forgot password?' : 'Check your email'}
          </p>
          <p className='text-gray-600 text-center'>
            {!isSent ?
              'No worries, we’ll send you reset instructions.'
              : `We sent a password reset link to ${getValues('email')}.`}
          </p>
        </div>
      </div>
      <div>
        {!isSent ? (
          <form
            className='flex flex-col gap-5'
            onSubmit={handleSubmit(onResetPasswordSubmit)}
          >
            <div className='flex flex-col gap-2'>
              <Controller
                control={control}
                name='email'
                render={({ field, formState: { errors } }) => (
                  <Input
                    label='Email'
                    placeholder='Enter your email'
                    errorText={errors.email?.message as string}
                    {...field}
                  />
                )}
              />
              {!!errors.root && !Object.keys(errors).filter(key => key !== 'root').length && (
                <p
                  className={`text-small text-error-600`}>{errors.root.message}</p>
              )}
            </div>
            <Button
              variant='primaryBrand'
              size='large'
              type='submit'
            >
              {resetPasswordState.fetching && <LoaderCircle className='animate-spin' />}
              Reset password
            </Button>
          </form>
        ) : (
          <div className='flex flex-col gap-6 items-center'>
            <div className='flex gap-1 items-center sm:flex-col'>
              Didn’t receive the email?
              <Button variant='nakedBrandLink' className='p-0' onClick={handleSubmit(onResetPasswordSubmit)}>
                {resetPasswordState.fetching && <LoaderCircle className='animate-spin' />}
                Click to resend
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}